/**
 * Check whether the date is today or in the future.
 *
 * @param {string} startDate // The date to check
 *
 * @returns {boolean}
 */
export function isTodayOrInFuture(startDate) {
  if (!startDate) {
    return false;
  }

  const date = new Date(startDate);
  date.setHours(23);
  date.setMinutes(59);

  return date.getTime() > new Date().getTime();
}

import { theme } from "../../setting";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Avatar, Typography, Divider } from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useUserContext from "./../../hooks/useUserContext";

/**
 * User data listitem from navigation
 *
 * @return {JSX.Element}
 */
export default function UserDataListItem() {
  const { t } = useTranslation();
  const { user } = useUserContext();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            color: theme.palette.black,
            backgroundColor: theme.palette.grey,
            margin: 2,
          }}
        >
          <AccountCircleIcon />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "12px",
              marginBottom: 0.5,
            }}
          >
            {t("logged_in_as")}
          </Typography>
          <Typography variant="p">{user && `${user.name}`}</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
}

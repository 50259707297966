import { useTranslation } from "react-i18next";
import Layout from "../components/general/Layout";
import { Typography, Box } from "@mui/material";
import { theme } from "./../setting";
import { Link } from "react-router-dom";

export default function TermsOfUseScreen() {
  const { t } = useTranslation();

  return (
    <Layout title={t("disclaimer")} showBackButton={true} backToMenu={true}>
      <Box
        sx={{
          background: theme.palette.white,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 3,
          marginTop: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="p"
          sx={{
            marginTop: 3,
          }}
        >
          Het opleidingsportaal van Pon Academy ("Drive") bevat, informatie over
          het trainingsaanbod, online trainingen, video's en praktische tips van
          medewerkers en trainers van Pon Academy en Pon's Automobielhandel B.V.
          bestemd voor medewerkers van dealers. Daarnaast bevat deze website
          links naar andere leeromgevingen van Pon Academy en van de
          merkfabrikanten. Het doel is vaardigheden te ontwikkelen en het kennis
          op peil te houden van de medewerkers binnen de dealerorganisatie van
          Pon's Automobielhandel B.V. en MAN truck en Bus B.V. Daarnaast geeft
          het inzicht in de gevolgde trainingen en opleidingen van de
          betreffende medewerker zoals geregistreerd in de Partner Medewerker
          Database ("PMDB")
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 2,
          }}
        >
          Deze Website en alle inhoud op de Website zijn het eigendom van en
          worden beheerd door Pon Academy afdeling van Pon's Automobielhandel
          B.V. met hoofdvestiging op het adres Zuiderinslag 2 3833 BP Leusden
          met KvK nummer: 31038797
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 2,
          }}
        >
          Bij een bezoek aan de website worden persoonsgegevens verzameld. Graag
          verwijzen wij u naar het{" "}
          <Link to="/nl/privacy-statement">Privacy-</Link> en{" "}
          <Link to="/nl/cookie-statement">cookie</Link> statement.
        </Typography>
      </Box>
    </Layout>
  );
}

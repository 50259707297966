import { useEffect, useState } from "react";
import { getAllTrainings } from "../dao/trainings";
import useUserContext from "./useUserContext";

export default function useTrainings() {
  const [trainings, setTrainings] = useState([]);
  const [trainingsLoading, setTrainingsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  const { user } = useUserContext();

  const fetchTrainingsData = (user) => {
    setTrainingsLoading(true);
    getAllTrainings(user.id)
      .then((res) => {
        setTrainings(res.items);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.message);
      })
      .finally(() => {
        setTrainingsLoading(false);
      });
  };

  useEffect(() => {
    if (user.id) {
      fetchTrainingsData(user);
    }
  }, [user]);

  return [trainings, trainingsLoading, hasError, error];
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sentry.
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from './Constants';

// Only init Sentry if we're in production or development
if (
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== ''
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [ new BrowserTracing() ],
    tracesSampleRate: 1.0,
    environment: SENTRY_ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
);

reportWebVitals();

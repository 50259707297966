import i18next from "i18next";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CodeIcon from "@mui/icons-material/Code";
import InfoIcon from "@mui/icons-material/Info";
import { theme } from "../../setting";

export default function HomeworkInformation({ module, homeworkData }) {
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.accent.PMS115,
          display: "flex",
          alignItems: "center",
          marginTop: 4,
          padding: 2,
        }}
      >
        <InfoIcon
          sx={{
            marginRight: 2,
          }}
        />
        <Typography variant='p'>
          {i18next.t("homework_notice")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <EditIcon
          sx={{
            marginRight: 2,
          }}
        />
        <Typography variant='p'>{i18next.t("homework")}</Typography>
      </Box>

      {module.id && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <SearchIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant='p'>
            {i18next.t("search_in_drive", { id: module.id })}
          </Typography>
        </Box>
      )}

      {module.status && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <CodeIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant='p'>{module.status}</Typography>
        </Box>
      )}

      {homeworkData?.description && (
        <>
          <Typography
            variant='h6'
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: 1,
              marginTop: 3,
            }}
          >
            {i18next.t("description")}
          </Typography>
          <Typography
            variant='p'
            sx={{
              whiteSpace: "pre-line",
              display: "block",
              marginBottom: 4,
            }}
            dangerouslySetInnerHTML={{
              __html: homeworkData?.description,
            }}
          />
        </>
      )}
    </>
  );
}

import { theme } from "../../setting";
import { useTranslation } from "react-i18next";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

/**
 * User logout listitem from navigation
 *
 * @return {JSX.Element}
 */
export default function LogOutUserListItem() {
  const { t } = useTranslation();

  const logOutUser = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('https://pon.com');
  };

  return (
    <ListItem
      sx={{
        padding: 0,
        flexWrap: "wrap",
      }}
    >
      <ListItemButton onClick={() => logOutUser()}>
        <ListItemText primary={t("log_out")} />
        <ListItemIcon
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <KeyboardArrowRightIcon
            sx={{
              color: theme.palette.grey,
            }}
          />
        </ListItemIcon>
      </ListItemButton>
      <Divider
        sx={{
          width: "100%",
        }}
      />
    </ListItem>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { ReactComponent as Animation } from "../assets/loading-animation.svg";
import Layout from "../components/general/Layout";
import useMediaFolders from "../hooks/useMedia";
import Loading from "../components/general/Loading";
import MediaItem from "../components/media/MediaItem";
import ErrorCard from "../components/general/ErrorCard";
import MediaFolderLink from "../components/media/MediaFolderLink";
import { searchMediatheek } from "../dao/media";
import { downloadFile } from "../helpers/downloadFile";
import { theme } from "../setting";
import i18next from "i18next";

export default function MediaLibraryScreen() {
  const { t } = useTranslation();
  const [mediaFolders, mediaFoldersLoading, hasError, error] =
    useMediaFolders();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  function handleBackButton() {
    setSearchValue("");
  }

  useEffect(() => {
    if (searchValue) {
      setSearchResultsLoading(true);
      searchMediatheek(searchValue).then((res) => {
        setSearchResults(res.files);
        setSearchResultsLoading(false);
      });
    } else {
      setSearchResults([]);
    }
  }, [searchValue]);

  if (mediaFoldersLoading) {
    return <Loading />;
  }

  return (
    <Layout
      title={t("media_library")}
      search={searchValue}
      setSearch={setSearchValue}
      searchPlaceholder={t('search_media_library')}
    >
      <Paper
        sx={{
          marginTop: 8,
          marginBottom: 3,
        }}
      >

      <Box
        sx={{
          backgroundColor: theme.palette.accent.PMS115,
          display: "flex",
          alignItems: "center",
          marginTop: 4,
          padding: 2,
        }}
      >
        <InfoIcon
          sx={{
            marginRight: 2,
          }}
        />
        <Typography variant='p'>
          {i18next.t("medialibrary_notice")}
        </Typography>
      </Box>

        {hasError && <ErrorCard errorMessage={error} />}
        {mediaFolders &&
          !searchValue &&
          mediaFolders.map((mediaFolder) => (
            <MediaFolderLink
              url={`/nl/mediatheek/${mediaFolder.id}`}
              state={{ folder: mediaFolder }}
              key={mediaFolder.id}
            >
              <MediaItem folder={mediaFolder} />
            </MediaFolderLink>
          ))}

        {searchValue && !searchResultsLoading && (
          <>
            <Box
              sx={{
                padding: 2,
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: theme.palette.font.main,
                cursor: "pointer",
              }}
              onClick={handleBackButton}
            >
              <ArrowBackIosNewIcon
                sx={{
                  marginRight: 1,
                }}
              />
              {t("back_to_begin")}
            </Box>
            {searchResults.map((searchResult) => (
              <Box
                key={searchResult.id}
                onClick={() => downloadFile(searchResult)}
                sx={{
                  cursor: "pointer",
                }}
              >
                <MediaItem folder={searchResult} />
              </Box>
            ))}
          </>
        )}

        {!mediaFolders && !searchResultsLoading && (
          <Typography
            variant='p'
            sx={{
              display: "block",
              padding: 2,
            }}
          >
            {t("no_folders_found")}
          </Typography>
        )}
      </Paper>

      {searchResultsLoading && searchValue && (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Animation />
        </Box>
      )}
    </Layout>
  );
}

import { OKTA_CLIENT_ID, OKTA_DOMAIN, OKTA_REDIRECT_URI, CODE_CHALLENGE } from '../Constants';
import { OktaAuth } from '@okta/okta-auth-js';

export const oktaAuth = new OktaAuth({
  issuer: `${OKTA_DOMAIN}`,
  clientId: `${OKTA_CLIENT_ID}`,
  redirectUri: `${OKTA_REDIRECT_URI}`,
  scopes: ["offline_access", "openid", "email", "profile"],
  codeChallenge: `${CODE_CHALLENGE}`,
  codeChallengeMethod: "S256",
});

export const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const OKTA_REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URI;
export const CRYPT_PASSWORD = process.env.REACT_APP_CRYPT_PASSWORD;
export const CODE_CHALLENGE = process.env.REACT_APP_CODE_CHALLENGE;

export const WARNING = "waarschuwing";

export const ANNOUNCEMENT_TYPES = {
  most_recent: "most_recent",
  announcement_page: "announcement_page",
};

export const TRAINING_TYPE_CLASSROOM = "classroom";
export const TRAINING_TYPE_EXAM = "exam";
export const TRAINING_TYPE_TASK = "task";
export const TRAINING_TYPE_ONLINE_MODULE = "onlineModule";
export const TRAINING_TYPE_LEARNING_PROGRAM = "learningProgram";
export const TRAINING_TYPE_VIRTUAL_CLASSROOM = "virtualClassroom";

export const TRAINING_STATUS_SIGNED_UP = 'Ingeschreven';
export const TRAINING_STATUS_UNFINISHED = 'Nog niet afgerond';
export const MODULE_FINISHED = "Afgerond";

export const DEBOUNCE_DELAY = 250;

export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_DSN = 'https://a3fbc213a2fc4d16aa3fada0d4d0e4aa@o230677.ingest.sentry.io/6691655';

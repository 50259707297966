import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../../setting";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Link } from "react-router-dom";

import Header from "./../general/Header";
import UserDataListItem from "./../auth/UserDataListItem";
import LogOutUserListItem from "./../auth/LogOutUserListItem";

export default function Navigation({ openDrawer, handleOpenDrawer }) {
  const { t } = useTranslation();

  const DRAWER_NAVIGATION_ITEMS = [
    {
      title: t("announcements"),
      path: "/nl/meldingen",
    },
    {
      title: t("disclaimer"),
      path: "/nl/disclaimer",
    },
    {
      title: t("privacy_statement"),
      path: "/nl/privacy-statement",
    },
    {
      title: t("cookie_statement"),
      path: "/nl/cookie-statement",
    },
    {
      title: t("terms_of_use"),
      path: "/nl/gebruiksvoorwaarden",
    },
  ];

  return (
    <Drawer
      open={openDrawer}
      onClose={() => handleOpenDrawer(false)}
      sx={{
        height: "calc(100% - 56px)",
        zIndex: 100,
      }}
      PaperProps={{
        sx: {
          width: "100%",
          flexShrink: 0,
          whiteSpace: "nowrap",
          zIndex: 10,
          paddingTop: "70px",
        },
      }}
    >
      <Header title={t("other_page_title")} />
      <List component="nav">
        <UserDataListItem />
        {DRAWER_NAVIGATION_ITEMS.map((item, i) => {
          return (
            <Fragment key={`${item.title}_${i}`}>
              <ListItem
                sx={{
                  padding: 0,
                  flexWrap: "wrap",
                }}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  onClick={() => handleOpenDrawer(false)}
                >
                  <ListItemText primary={item.title} />
                  <ListItemIcon
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <KeyboardArrowRightIcon
                      sx={{
                        color: theme.palette.grey,
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
                <Divider
                  sx={{
                    width: "100%",
                  }}
                />
              </ListItem>
            </Fragment>
          );
        })}
        <LogOutUserListItem />
      </List>
    </Drawer>
  );
}

import { postDataToApi } from "./api";
import { errorMessageHandler } from "./../utils/error";

/**
 * Report presence of user by scanning QR code
 * @param {string} userId // The userId.
 * @param {string} reportUrl // The URL to report to.
 */
export async function reportPresence(userId, reportUrl) {
  // The report URL we get from the QR code contains an unnecessary part.
  // Remove this and decode the URL before we send it to the backend.
  const sanitizedReportUrl = reportUrl.replace('http://www.drive.pon.com/QR/Attendance?', '');
  const decodedReportUrl = decodeURIComponent(sanitizedReportUrl);

  const payload = JSON.stringify({
    userId,
    reportUrl: decodedReportUrl,
  });

  return postDataToApi("/presence", {
    body: payload,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        const err = new Error(`${res.status} response`);
        err.response = res;
        throw err;
      }
      return true;
    })
    .catch((err) => {
      errorMessageHandler(err);
      return false;
    });
}

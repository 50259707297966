import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/general/Layout";
import AnnouncementItem from "./../components/announcements/AnnouncementItem";
import useAnnouncements from "./../hooks/useAnnouncements";

import { ANNOUNCEMENT_TYPES } from "./../Constants";
import useTrainings from "../hooks/useTrainings";
import { isTodayOrInFuture } from "../helpers/dateHelpers";
import AnnouncementTrainingItem from "../components/announcements/AnnouncementTrainingItem";
import SubModuleLink from "../components/subModules/SubModuleLink";

export default function DashboardScreen() {
  const { t } = useTranslation();
  const [announcements, announcementsLoading] = useAnnouncements();
  const [trainings, trainingsLoading] = useTrainings();
  const [upcomingTraining, setUpcomingTraining] = useState([]);

  useEffect(() => {
    !trainingsLoading &&
      setUpcomingTraining(
        trainings.find(
          (training) =>
            training.type !== "onlineModule" &&
            isTodayOrInFuture(training.startDate)
        ) || []
      );
  }, [trainings, trainingsLoading]);

  return (
    <Layout title={t("dashboard")}>
      {!trainingsLoading && Object.keys(upcomingTraining).length > 0 && (
        <SubModuleLink
          url={`/nl/trainingen/${upcomingTraining.trainingRecordId}`}
          state={{ training: upcomingTraining }}
          key={upcomingTraining.id}
        >
          <AnnouncementTrainingItem training={upcomingTraining} />
        </SubModuleLink>
      )}

      {!announcementsLoading && Object.keys(announcements).length > 0 && (
        <AnnouncementItem
          announcement={announcements[0]}
          type={ANNOUNCEMENT_TYPES.most_recent}
        />
      )}
    </Layout>
  );
}

import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import Layout from "../components/general/Layout";
import TrainingInformation from "../components/subModules/TrainingInformation";
import { theme } from "../setting";
import { TRAINING_TYPE_TASK } from "../Constants";
import HomeworkInformation from "../components/subModules/HomeworkInformation";

export default function SubModuleDetailScreen() {
  const location = useLocation();
  const { module, enrichedData } = location.state;

  const [sessionData, setSessionData] = useState({});
  const [homeworkData, setHomeworkData] = useState({});

  useEffect(() => {
    if (module.sessionId) {
      enrichedData.sessions.forEach((session) => {
        if (session.id === module.sessionId) {
          setSessionData(session);
        }
      });
    }

    enrichedData.subModules.forEach((subModule) => {
      if (subModule.id === module.id) {
        setHomeworkData(subModule);
      }
    });
  }, [module, enrichedData]);

  return (
    <Layout title={module.title} showBackButton={true}>
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Paper
          sx={{
            padding: 2,
          }}
        >
          <Typography
            variant='h5'
            sx={{
                fontWeight: "bold",
            }}
          >
            {module.title}
          </Typography>
          <Typography
            variant='p'
            sx={{
                color: theme.palette.font.lighter,
            }}
          >
            {module.id}
          </Typography>

          {module.type === TRAINING_TYPE_TASK ?
            <HomeworkInformation module={module} homeworkData={homeworkData}/>
            :
            <TrainingInformation module={module} sessionData={sessionData}/>
          }

        </Paper>
      </Box>
    </Layout>
  );
}

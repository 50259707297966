import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import useDebounce from "../../helpers/useDebounce";
import { DEBOUNCE_DELAY } from "../../Constants";

export default function SearchBar({search, setSearch, searchPlaceholder}) {
  const [searchValue, setSearchValue] = useState(search);

  // Debounced function
  const debouncedValue = useDebounce(searchValue, DEBOUNCE_DELAY);

  useEffect(() => {
    setSearch(debouncedValue);
  }, [debouncedValue, setSearch]);

  useEffect(() => {
    if (!search) {
      setSearchValue('');
    }
  }, [search]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 1,
      }}
    >
      <input
        className="media-search"
        placeholder={searchPlaceholder}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
    </Box>
  );
}

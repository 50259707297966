import CryptoJS from "crypto-js";
import { CRYPT_PASSWORD } from "./../Constants";

export function decrypt(stringToDecrypt) {
  const decryptedContent = CryptoJS.AES.decrypt(
    stringToDecrypt,
    CRYPT_PASSWORD
  );

  return decryptedContent.toString(CryptoJS.enc.Utf8);
}

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Paper, Typography, List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";

import Layout from "../components/general/Layout";
import { useMediaFiles } from "../hooks/useMedia";
import Loading from "../components/general/Loading";
import ErrorCard from "../components/general/ErrorCard";
import MediaItem from "../components/media/MediaItem";
import { Box } from "@mui/system";
import { ReactComponent as Animation } from "../assets/loading-animation.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { theme } from "../setting";
import { searchMediatheek } from "../dao/media";

export default function MediaFileScreen() {
  const { t } = useTranslation();
  const location = useLocation();
  const { folder } = location.state;
  const [pageToken, setPageToken] = useState("");
  const [files, setFiles] = useState([]);
  const [mediaFiles, mediaFilesLoading, hasError, error] = useMediaFiles(
    folder.id,
    pageToken
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  function handleScroll() {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      // Prevent double files since the last page has en empty 'nextPageToken'.
      if (mediaFiles.nextPageToken) {
        setPageToken(mediaFiles.nextPageToken);
      }
    }
  }

  // Execute on every update.
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (mediaFiles.files) {
      setFiles((prevState) => [...prevState, ...mediaFiles.files]);
    }
  }, [mediaFiles]);

  useEffect(() => {
    if (searchValue && folder) {
      setSearchResultsLoading(true);
      searchMediatheek(searchValue, folder.id).then((res) => {
        setSearchResults(res.files);
        setSearchResultsLoading(false);
      });
    } else {
      setSearchResults([]);
    }
  }, [folder, searchValue]);

  if (!files.length && mediaFilesLoading) {
    return <Loading />;
  }

  return (
    <Layout
      title={t("media_library")}
      onScroll={handleScroll}
      setSearch={setSearchValue}
      searchPlaceholder={t("search_media_library")}
    >
      <Paper
        sx={{
          marginTop: 8,
          marginBottom: 3,
        }}
      >
        {hasError && <ErrorCard errorMessage={error} />}

        {!searchResultsLoading && (
          <Box
            sx={{
              padding: 2,
            }}
          >
            <Link
              to="/nl/mediatheek"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: theme.palette.font.main,
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  marginRight: 1,
                }}
              />
              {t("back_to_begin")}
            </Link>
          </Box>
        )}

        <List>
          {files &&
            !searchValue &&
            files.map((filteredFile) => (
              <ListItem
                key={filteredFile.id}
                sx={{
                  padding: 0,
                  flexWrap: "wrap",
                }}
              >
                <MediaItem folder={filteredFile} />
              </ListItem>
            ))}

          {searchValue &&
            searchResults.map((searchResult) => (
              <ListItem
                key={searchResult.id}
                sx={{
                  padding: 0,
                  flexWrap: "wrap",
                }}
              >
                <MediaItem folder={searchResult} />
              </ListItem>
            ))}
        </List>

        {!files && !searchResultsLoading && (
          <Typography
            variant="p"
            sx={{
              display: "block",
              padding: 2,
            }}
          >
            {t("no_files_found")}
          </Typography>
        )}
      </Paper>

      {mediaFilesLoading ||
        (searchResultsLoading && (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Animation />
          </Box>
        ))}
    </Layout>
  );
}

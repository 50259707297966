import { Box, Typography } from "@mui/material";
import { theme } from "../../setting";
import TrainingTypeIcon from "./TrainingsTypeIcon";

export default function TrainingsContent({ content, status }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: 2,
      }}
    >
      <TrainingTypeIcon
        type={content.type}
        sx={{
          color: theme.palette.white,
          borderRadius: "16px",
          padding: "4px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 2,
        }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "16px",
            color: theme.palette.black,
          }}
        >
          {content.title}
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "14px",
            color: theme.palette.font.lighter,
          }}
        >
          {status}
        </Typography>
      </Box>
    </Box>
  );
}

import { getDataFromApi } from "./api";
import { errorMessageHandler } from "./../utils/error";

/**
 * Get announcements.
 */
export async function getAnnouncements() {
  return getDataFromApi("/announcements")
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

import moment from "moment";
import log from "loglevel";
import { Typography, Box, Paper } from "@mui/material";
import IosShare from "@mui/icons-material/IosShare";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { theme } from "./../../setting";
import { WARNING, ANNOUNCEMENT_TYPES } from "./../../Constants";
import i18n from "../../i18n";

const GetSeverityIcon = ({ severity, sx }) => {
  if (severity.toLowerCase() === WARNING) {
    return <ReportProblemIcon sx={{ ...sx }} />;
  } else {
    return <InfoIcon sx={{ ...sx }} />;
  }
};

export default function AnnouncementItem({ announcement, type }) {
  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          text: announcement.title,
        })
        .then(() => {
          log.info("Successfully shared");
        })
        .catch((error) => {
          log.error("Something went wrong", error);
        });
    }
  };

  return (
    <Paper sx={{ marginTop: 3 }} elevation={1}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        {type === ANNOUNCEMENT_TYPES.announcement_page && (
          <GetSeverityIcon
            severity={announcement.severity}
            sx={{
              alignSelf: "flex-start",
            }}
          />
        )}
        <Box
          sx={{
            paddingLeft: 2,
            paddingRight: 4,
            position: "relative",
          }}
        >
          {type === ANNOUNCEMENT_TYPES.most_recent && (
            <Typography
              variant="p"
              sx={{
                fontSize: "12px",
                color: theme.palette.font.lighter,
                marginBottom: 1,
                display: "block",
              }}
            >
              {i18n.t("most_recent_announcement")}
            </Typography>
          )}
          <Typography
            variant="p"
            sx={{
              marginBottom: 2,
              display: "block",
            }}
          >
            {announcement.title}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontSize: "12px",
              color: theme.palette.font.lighter,
              marginTop: 1,
              display: "block",
            }}
          >
            {moment(announcement.startAt, "M/D/YYYY HH:mm:ss").format(
              "D MMM YYYY"
            )}
          </Typography>
          {type === ANNOUNCEMENT_TYPES.most_recent && (
            <NotificationsIcon
              sx={{
                position: "absolute",
                right: "0",
                top: "0",
                backgroundColor: theme.palette.font.lighter,
                borderRadius: "16px",
                color: theme.palette.white,
                padding: "4px",
              }}
            />
          )}
        </Box>
        {type === ANNOUNCEMENT_TYPES.announcement_page &&
          (navigator.userAgent.match(/Android/i) ||
            (navigator.userAgent.match(/iPhone/i) && (
              <IosShare
                onClick={handleShareClick}
                sx={{
                  color: theme.palette.font.lighter,
                }}
              />
            )))}
      </Box>
    </Paper>
  );
}

// Happily borrowed from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
import { useState, useEffect } from 'react';

/**
 * This hook creates a debounced state, allowing multiple rapid updates
 * to the state to trigger only limited updates to dependant elements.
 *
 * @param {*} value Initial value of this state
 * @param {number} delay Delay after which to update value
 *
 * @returns {*} The new value after x amount of time
 */
export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed
    // within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);
  return debouncedValue;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { localizeURL } from './Link';
import { locales } from '../i18n';

/**
 * @returns {JSX.Element} ...
 */
export default function LanguageDetector() {
  const { i18n } = useTranslation();

  if (!i18n) {
    return null;
  }
  const validLocale = determineKnownLocale(i18n.language);
  i18n.changeLanguage(validLocale);
  const localizedURL =
    localizeURL(validLocale, window.location.pathname) + window.location.search;

  return <Navigate to={localizedURL} />;
}

/**
 * @param {string} locale Locale as a string (ll-RR)
 * @returns {string} Closest matching locale known to this application
 */
function determineKnownLocale(locale) {
  const fullMatch = locales.find(
    (knownLocale) => knownLocale.toLowerCase() === locale.toLowerCase()
  );
  if (fullMatch) {
    return fullMatch;
  }
  const languageMatch = locales.find((knownLocale) => {
    const lcKnown = knownLocale.toLowerCase();
    const lcLocale = locale.toLowerCase();
    return lcKnown.startsWith(lcLocale) || lcLocale.startsWith(lcKnown);
  });
  if (languageMatch) {
    return languageMatch;
  }

  return locales[0];
}

import React, { useState, useEffect } from "react";
import { Paper, Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";

import Header from "./Header";
import Footer from "./Footer";
import { theme } from "./../../setting";
import { useTranslation } from "react-i18next";

/**
 * The default layout
 *
 * @param {string} title // Page title
 * @param {object} children // Children elements
 *
 * @return {JSX.Element}
 */
export default function Layout({
  title,
  children,
  padding = true,
  search = "",
  setSearch = false,
  searchPlaceholder = "",
  showBackButton = false,
  backToMenu = false,
}) {
  // In case user visits the path directly, the bottom navigation will have the correct item set as active.
  const pathname = window.location.pathname;
  const { oktaAuth } = useOktaAuth();
  const [value, setValue] = useState(pathname);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { t } = useTranslation();

  const handleOpenDrawer = (newValue) => {
    setOpenDrawer(newValue);

    // If the drawer is closed, restore previous active page.
    if (!newValue) {
      setValue(pathname);
    }
  };

  useEffect(() => {
    async function authenticate() {
      const accessToken = await oktaAuth.tokenManager.get("accessToken");
      const isAccessTokenValid = accessToken?.expiresAt > Date.now() / 1000;

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );

      if (!isAccessTokenValid) {
        oktaAuth.tokenManager.remove("accessToken");
        oktaAuth.tokenManager.remove("idToken");
        oktaAuth.tokenManager.remove("refreshToken");
        window.location = originalUri;
      }
    }
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header
        title={title}
        search={search}
        setSearch={setSearch}
        searchPlaceholder={searchPlaceholder}
      />
      <Paper
        elevation={0}
        sx={{
          background: theme.palette.backgroundColor,
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <Box
          sx={{
            paddingTop: 8,
            paddingBottom: 6,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              paddingLeft: padding ? 3 : 0,
              paddingRight: padding ? 3 : 0,
              maxWidth: "840px",
              width: "100%",
            }}
          >
            {showBackButton && (
              <Button
                onClick={() => {
                  backToMenu ? setOpenDrawer(true) : window.history.back();
                }}
                sx={{
                  color: theme.palette.black,
                  marginTop: 2,
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    marginRight: 1,
                  }}
                />
                {t("back")}
              </Button>
            )}
            {children}
          </Box>
        </Box>
      </Paper>
      <Footer
        handleOpenDrawer={handleOpenDrawer}
        value={value}
        setValue={setValue}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />
    </>
  );
}

/**
 *
 * @param {string} address // The address
 * @param {number} zoomLevel // Zoom level on the map
 * @param {string} provider // Platform
 *
 * @return {string}
 */
export function getMapsLink(address, provider, zoomLevel = 15) {
    if (!provider) {
      const platform = navigator.platform.toLowerCase();

      if (platform.includes('iphone') || platform.includes('ipad')) {
        provider = 'apple';
      } else {
        provider = 'google';
      }
    }

    return createMapLink(address, zoomLevel, provider);
}

function createMapLink(address, zoomLevel = 15, provider = 'google') {
    const link = {
      google: `http://maps.google.com/maps?q=${encodeURIComponent(
        address,
      )}&z=${zoomLevel}`,
      apple: `http://maps.apple.com/?address=${encodeURIComponent(
        address,
      )}&z=${zoomLevel}`,
    };

    return link[provider];
}

import { getDataFromApi, postDataToApi } from "./api";
import { errorMessageHandler } from "./../utils/error";

/**
 * Get Media folders.
 */
export async function getMediaFolders() {
  return getDataFromApi("/mediatheek/getFolder")
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

/**
 * Get Media files.
 *
 * @param {string} folderId // Folder to fetch files from.
 */
export async function getMediaFiles(folderId, pageToken) {
  return getDataFromApi(`/mediatheek/getFolder/${folderId}?token=${pageToken}`)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

/**
 * Get media file.
 *
 * @param {string} fileId // File to fetch.
 */
export async function getFile(fileId) {
  return getDataFromApi(`/mediatheek/getFile/${fileId}`)
    .then((res) => {
      return res.blob();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

/**
 * Search media file.
 *
 * @param {string} searchValue // Value to search for.
 * @param {array} folderId // Folder ID to search in.
 */
export async function searchMediatheek(searchValue, folderId) {
  return postDataToApi(`/mediatheek/search`, {
    body: JSON.stringify({
      search: encodeURIComponent(JSON.stringify(searchValue)),
      parentIds: (folderId ? [ folderId ] : ''),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

import { errorMessageHandler } from "../utils/error";
import { getDataFromApi } from "./api";

/**
 * Get trainings.
 *
 * @param string userId // The user ID.
 */
export async function getAllTrainings(userId) {
  return getDataFromApi(`/trainings?userId=${userId}`)
  .then((res) => {
    return res.json();
  })
  .catch((err) => {
    errorMessageHandler(err);
    return Promise.reject(err);
  });
}

/**
 * Get trainings enrich data.
 *
 * @param {string} enrichApiURL // The enricht API url.
 */
export async function getTrainingEnrichData(enrichApiURL) {
  return getDataFromApi(enrichApiURL)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      errorMessageHandler(err);
      return Promise.reject(err);
    });
}

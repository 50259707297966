import { theme } from "../../setting";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography, Paper, Button } from "@mui/material";
import { lighten } from "@mui/material/styles";

/**
 *
 * @param {boolean} visible - Cookie popup visible or not
 * @param {function} acceptCookies - Accept cookies
 * @param {function} declineCookies - Decline cookies
 * @return {JSX.element} ...
 */
export default function CookieBanner({
  visible,
  acceptCookies,
  declineCookies,
}) {
  const { t } = useTranslation();

  return (
    visible && (
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          zIndex: 1000,
        }}
        elevation={4}
      >
        <Typography variant="p" sx={{ fontSize: "15px" }}>
          Pon Academy gebruikt cookies om bijvoorbeeld de website te verbeteren
          en te analyseren. Door op 'Accepteren' te klikken geef je Pon
          toestemming voor het gebruik van noodzakelijke en optimale cookies op
          onze website. Als je meer wilt weten over hoe wij met jouw
          persoonlijke gegevens omgaan, kun je de{" "}
          <Link to="/nl/privacy-statement">Privacyverklaring</Link> en het{" "}
          <Link to="/nl/cookie-statement">Cookiebeleid</Link> raadplegen.
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginTop: 2,
          }}
        >
          <Button
            onClick={acceptCookies}
            size="small"
            sx={{
              padding: "8px 16px",
              background: theme.palette.primary.main,
              color: theme.palette.white,
              "&:hover": {
                background: lighten(theme.palette.primary.main, 0.3),
              },
              "&:focus": {
                background: lighten(theme.palette.primary.main, 0.3),
              },
            }}
          >
            {t("cookie_button_accept")}
          </Button>
          <Button
            sx={{
              padding: "8px 16px",
              background: theme.palette.backgroundColor,
              marginLeft: "8px",
              "&:hover": {
                background: lighten(theme.palette.backgroundColor, 0.3),
              },
              "&:focus": {
                background: lighten(theme.palette.backgroundColor, 0.3),
              },
            }}
            onClick={declineCookies}
          >
            {t("cookie_button_decline")}
          </Button>
        </Box>
      </Paper>
    )
  );
}

import moment from "moment";
import { Typography, Paper } from "@mui/material";
import { theme } from "./../../setting";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import TrainingTypeIcon from "../trainings/TrainingsTypeIcon";
import { TRAINING_TYPE_LEARNING_PROGRAM } from "../../Constants";
import TrainingsContent from "../trainings/TrainingsContent";
import { getStatusTrainingOverview } from "../../utils/status";

export default function AnnouncementTrainingItem({ training, order }) {
  const { t } = useTranslation();

  return (
    <Paper
      key={training.id}
      sx={{
        marginTop: 3,
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.subjectColors.learningProgram}`
      }}
    >
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 4,
        }}
      >
        {order === 0 && (
          <Typography
            variant='p'
            sx={{
              fontSize: "12px",
              color: theme.palette.font.lighter,
              marginBottom: 2,
              display: "block",
            }}
          >
            {t("next_training")}
          </Typography>
        )}
        <Typography
          variant='h4'
          sx={{
            fontSize: "20px",
            marginBottom: 1,
          }}
        >
          {training.title}
        </Typography>
        <Typography
          variant='p'
          sx={{
            color: theme.palette.status.positiveColor,
            fontWeight: "500",
          }}
        >
          {moment(training.startDate).format("D MMMM YYYY")}
        </Typography>

        {training.subModules?.task &&
          <Box
            sx={{
              marginTop: 2,
              "& .MuiBox-root:last-of-type": {
                marginBottom: 0,
              },
            }}
          >
            {training.subModules.task.map((task) => (
              <TrainingsContent key={task.id} content={task} status={getStatusTrainingOverview(task.type, task.status, task.startDate)} />
            ))}
          </Box>
        }
      </Box>
      <TrainingTypeIcon
        type={TRAINING_TYPE_LEARNING_PROGRAM}
        sx={{
          color: theme.palette.white,
          borderRadius: "16px",
          padding: "4px",
        }}
      />
    </Paper>
  );
}

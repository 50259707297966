import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
import { theme } from "./../setting";
import Layout from "../components/general/Layout";

export default function CookieStatementScreen() {
  const { t } = useTranslation();

  return (
    <Layout title={t("cookie_statement")} showBackButton={true} backToMenu={true}>
      <Box
        sx={{
          background: theme.palette.white,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 3,
          display: "flex",
          flexDirection: "column",
          marginTop: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Maakt Pon gebruik van cookies?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Ja, voor onze website maken we gebruik van cookies. Dat zijn kleine
          (tijdelijke) bestanden die door een website op jouw browser worden
          geplaatst. Dat gebeurt op je computer, je mobiel en/of tablet.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Van welke cookies maakt Pon gebruik en waarom?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Onze websites maken gebruik van twee soorten cookies:
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginTop: 2,
            fontWeight: "bold",
          }}
        >
          Functionele cookies
        </Typography>
        <Typography variant="p">
          Deze cookies zorgen er voor dat onze websites goed werken. Hiermee
          kunnen we er bijvoorbeeld voor zorgen dat onze websites op het juiste
          schermformaat aan jou getoond worden.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginTop: 2,
            fontWeight: "bold",
          }}
        >
          Analytische cookies
        </Typography>
        <Typography variant="p">
          Met deze cookies kunnen wij jou helpen de beste vacatures voor jou te
          vinden. Ook helpen ze ons om te meten hoe vaak onze websites worden
          bezocht en welke onderdelen van onze websites het meest bekeken
          worden. Op basis van deze gegevens kunnen wij onze websites blijven
          verbeteren.
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Google Analytics is een webanalysedienst die wordt aangeboden door
          Google Inc. (Google). Google Analytics gebruikt cookies om het gebruik
          van de website door Gebruikers te analyseren. Google gebruikt
          geaggregeerde statistische gegevens om Pon een inzicht te geven in de
          wijze waarop Gebruikers de website gebruiken. Op basis van de
          richtlijnen van de Nederlandse Autoriteit Persoonsgegevens hebben we
          Google Analytics privacyvriendelijker gemaakt door: <br />
          <ul>
            <li>
              ervoor te zorgen dat we een “Verwerkingscontract” hebben met
              Google;
            </li>
            <li>
              anonimiseren van IP-adressen te activeren in Google Analytics;
            </li>
            <li>
              ervoor te zorgen dat het delen van gegevens met Google niet
              plaatsvindt.
            </li>
          </ul>
          Google mag deze gegevens alleen verstrekken aan externe partijen als
          Google volgens de wet verplicht is om dit te doen of in de mate die
          noodzakelijk is voor externe partijen om gegevens namens Google te
          verwerken.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Kan ik cookies verwijderen?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Ja, dat kan altijd. De manier waarop is voor iedere browser anders. Op
          aboutcookies.org staat precies voor iedere browser uitgelegd hoe je
          dit kunt doen. Als je cookies verwijdert, kan het daardoor wel
          gebeuren dat onze websites niet meer (goed) functioneren.
        </Typography>
      </Box>
    </Layout>
  );
}

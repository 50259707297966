import { useTranslation } from "react-i18next";
import Layout from "../components/general/Layout";
import { Typography, Box } from "@mui/material";
import { theme } from "./../setting";

export default function PrivacyStatementScreen() {
  const { t } = useTranslation();

  return (
    <Layout title={t("privacy_statement")} showBackButton={true} backToMenu={true}>
      <Box
        sx={{
          background: theme.palette.white,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 3,
          display: "flex",
          flexDirection: "column",
          marginTop: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Toepasselijkheid
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Deze privacyverklaring is van toepassing op de verzameling, het
          gebruik en andere vormen van verwerking van persoonsgegevens door Pon
          Academy in het Leermanagement Systeem Drive (hierna “Drive”) en
          Partner Medewerkers Database (hierna “PMDB”).
        </Typography>

        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Wie is verantwoordelijk voor de verwerking van jouw persoonsgegevens?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Pon Academy is toegewijd aan het respecteren en beschermen van je
          privacy en de veiligheid van de persoonsgegevens die je met ons deelt.
          Pon Academy (gevestigd aan de Zuiderinslag 12 te Leusden en
          telefonisch bereikbaar via <a href="tel:+31334341600">033-4341600</a>)
          maakt onderdeel uit van Pon's Automobielhandel B.V. (gevestigd aan de
          Zuiderinslag 2 te Leusden en telefonisch bereikbaar via{" "}
          <a href="tel:+31334949944">033-4949944</a>) welke verantwoordelijke is
          in de zin van privacywetgeving voor de verzameling en verwerking van
          jouw persoonsgegevens in Drive en PMDB.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Waarom verzamelen we jouw persoonsgegevens?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Bij gebruik van Drive en/of PMDB verzamelen we enkele persoonsgegevens
          van je. Deze persoonsgegevens verwerken wij om jouw deelname aan onze
          trainingen mogelijk te maken, met je te communiceren over
          opleidingen/certificeringen of de voortgang van je deelname aan
          trainingen of om je overige communicatie te versturen omtrent
          opleidingsaangelegenheden. Jouw scores en behaalde certificeringen
          worden gebruikt om rapportages mee te kunnen maken zoals het
          dealerkwalificatie overzicht en voor analyses over de (individuele)
          voortgang en het succes van trainingsprogramma's. Wij verwerken jouw
          gegevens op basis van de volgende rechtsgronden: 1. verwerkingen zijn
          noodzakelijk voor de uitvoering van een overeenkomst tussen jouw
          werkgever en Pon Academy en/of 2. indien dit noodzakelijk is voor de
          behartiging van een gerechtvaardigd belang van Pon Academy.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Welke persoonsgegevens verzamelen wij?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          We verwerken de volgende gegevens van jou:
          <br />
          <ul>
            <li>Pon ID</li>
            <li>Voornaam, tussenvoegsel, achternaam</li>
            <li>Geboortedatum</li>
            <li>E-mailadres</li>
            <li>Telefoonnummer</li>
            <li>Naam bedrijf</li>
            <li>Vestiging</li>
            <li>Functie</li>
            <li>
              Contractgegevens: aantal uren per week (alleen voor PMDB van
              toepassing)
            </li>
            <li>
              Opleidingsgegevens: inschrijvingen trainingen, status training
              (bijvoorbeeld ingeschreven, gevolgd, afwezig, vrijgesteld,
              geslaagd), score van toetsing, certificering behaald.
            </li>
          </ul>
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Hoe delen we jouw persoonsgegevens of dragen we deze over?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Pon Academy kan jouw persoonsgegevens delen met externe partijen om
          training gerelateerde activiteiten ten behoeve van Pon Academy uit te
          voeren namens Pon Academy. In dit geval vereist Pon Academy dat deze
          externe partijen jouw persoonsgegevens zorgvuldig verwerken en
          beschermen. Verder kunnen jouw gegevens worden ingezien door jouw
          werkgever in de persoon van jouw leidinggevende,
          PMDB-verantwoordelijke en/of de opleidingsverantwoordelijke van jouw
          werkgever. Ook kunnen gegevens over jouw certificeringen ter
          beschikking worden gesteld voor auditdoeleinden om te bepalen of een
          dealer voldoet aan bepaalde kwaliteitsnormen.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Hoe beschermen we jouw persoonsgegevens?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Pon Academy zal passende technische en organisatorische
          beveiligingsmaatregelen nemen tegen verlies of onwettige verwerking
          van jouw persoonsgegevens. Dit kan het gebruik van beveiligde
          registratieformulieren, codering van gegevens en toegangsbeperking tot
          jouw persoonsgegevens omvatten.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Hoe lang bewaren we jouw persoonsgegevens?
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          We bewaren je gegevens niet langer dan noodzakelijk of wettelijk
          verplicht. Je persoonsgegevens worden binnen een redelijke termijn
          verwijderd. We hebben hiervoor een bewaartermijnenbeleid.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Jouw rechten
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Je kunt op elk moment inzicht vragen in jouw opgeslagen
          persoonsgegevens, de herkomst ervan en aan wie of wat voor instanties
          die gegevens worden verstrekt, en met welk doel ze worden opgeslagen.
          Ook heb je het recht om te verzoeken om rectificatie, blokkering en/of
          verwijdering van jouw gegevens wanneer jouw gegevens onrechtmatig zijn
          verwerkt of onjuist zijn. Om je verzoek in te dienen kun je contact
          opnemen met het hieronder vermelde e-mailadres.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginTop: 3,
          }}
        >
          Vragen en feedback
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 1,
          }}
        >
          Stuur jouw vragen en opmerkingen voor Pon Academy naar
          <a href="mailto:pon.academy@pon.com">pon.academy@pon.com</a>. Stuur
          jouw vragen en opmerkingen met betrekking tot privacy naar{" "}
          <a href="mailto:privacy@pon.com">privacy@pon.com</a>.<br />
          <br />
          Dit privacy statement is voor het laatst herzien op 14 maart 2018.
        </Typography>
      </Box>
    </Layout>
  );
}

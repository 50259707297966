import {
  TRAINING_STATUS_SIGNED_UP,
  TRAINING_STATUS_UNFINISHED,
} from "../Constants";

// Sort descending based on endDate.
function sortDescending(a, b) {
  if (a.endDate > b.endDate) {
    return 1;
  } else if (a.endDate === b.endDate) {
    return 0;
  } else {
    return -1;
  }
}

// Sort ascending based on endDate.
function sortAscending(a, b) {
  if (a.endDate > b.endDate) {
    return -1;
  } else if (a.endDate === b.endDate) {
    return 0;
  } else {
    return 1;
  }
}

/**
 * Check if training title matches search value.
 *
 * @param {object} training // Single training
 * @param {string} searchTerm // Search value
 *
 * @returns {boolean}
 */
function hasExactMatch(training, searchTerm) {
  if (typeof searchTerm !== "string" || searchTerm === "") {
    return false;
  }

  const query = searchTerm.toLowerCase();

  return training.title.toLowerCase().indexOf(query) > -1;
}

/**
 * Filter and sort the trainings in the correct way.
 *
 * @param {object} trainings // Trainings to filter
 * @param {string} searchValue // SearchValue
 *
 * @returns {object} // The filtered trainings
 */
export function filterTrainings(trainings, searchValue) {
  if (searchValue) {
    // Clean up searchValue
    const cleanedSearchValue = searchValue.trim().split(" ");

    // Get the search results
    const searchResults = cleanedSearchValue.reduce((memo, searchTerm) => {
      return memo.filter((training) => hasExactMatch(training, searchTerm));
    }, trainings);

    return searchResults.sort(sortDescending);
  } else {
    // Filter out the trainings by status.
    const filteredTrainings = trainings.filter((training) => {
      if (
        training.status === TRAINING_STATUS_SIGNED_UP ||
        training.status === TRAINING_STATUS_UNFINISHED
      ) {
        return !!training;
      }
      return false;
    });

    // Create two buckets (one with upcoming trainings and one with past trainings).
    const todayTime = new Date().getTime();
    const splitResult = filteredTrainings.reduce(
      (memo, r) => {
        const bucket = new Date(r.endDate).getTime() > todayTime ? 1 : 0;
        memo[bucket].push(r);
        return memo;
      },
      [[], []]
    );

    // Sort the upcomming trainings DESC.
    // Sort the past trainings ASC.
    return []
      .concat(splitResult[1].sort(sortDescending))
      .concat(splitResult[0].sort(sortAscending));
  }
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import DetectKnownLocaleFromURL from "./i18n/DetectKnownLocaleFromURL";

import nl from "./i18n/nl";

const browserLanguageDetector = new BrowserLanguageDetector();
browserLanguageDetector.addDetector(DetectKnownLocaleFromURL);

const resources = {
  nl,
};

export const locales = Object.keys(resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(browserLanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: [
        DetectKnownLocaleFromURL.name,
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "subdomain",
      ],
      knownLocales: locales,
    },
  });

export default i18n;

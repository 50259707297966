import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/general/Layout";
import AnnouncementItem from "../components/announcements/AnnouncementItem";
import useAnnouncements from "./../hooks/useAnnouncements";

import { ANNOUNCEMENT_TYPES } from "./../Constants";

export default function AnnouncementsScreen() {
  const { t } = useTranslation();
  const [announcements, announcementsLoading] = useAnnouncements();

  return (
    <Layout title={t("announcements")} showBackButton={true} backToMenu={true}>
      {!announcementsLoading &&
        announcements.map((announcement, index) => (
          <AnnouncementItem
            announcement={announcement}
            type={ANNOUNCEMENT_TYPES.announcement_page}
            key={`announcement_${index}`}
          />
        ))}
    </Layout>
  );
}

import { createTheme } from '@mui/material/styles';

// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createTheme({
  palette: {
    white: "#FFFFFF",
    grey: "#CCCCCC",
    black: "#000000",
    backgroundColor: "#DEE4E6",
    primary: {
      main: "#0f86af",
    },
    font: {
      main: "#323739",
      lighter: "#626769",
      lightest: "#929799",
    },
    status: {
      positiveColor: "#2aa12a",
      attentionColor: "#e28d04",
      greyedOutColor: "#929799",
    },
    accent: {
      coolGrey: "rgba(191, 191, 191, 1.0)",
      processBlack: "rgba(0, 0, 0, 1.0)",
      PMS309: "rgba(0, 68, 92, 1.0)",
      PMS072: "rgba(27, 19, 124, 1.0)",
      processCyan: "rgba(0, 153, 204, 1.0)",
      PMS187: "rgba(158, 19, 20, 1.0)",
      PMS115: "rgba(255, 204, 0, 1.0)",
    },
    pon: {
      PMS5503: "rgba(60, 190, 190, 1.0)",
      PMS877: "rgba(153, 153, 153, 1.0)",
      warmGray: "rgba(193, 185, 174, 1.0)",
      black7: "rgba(82, 76, 58, 1.0)",
    },
    subjectColors: {
      learningProgram: "#FF1952",
      training: "#FF1952",
      classroom: "#FF1952",
      onlineModule: "#00BEFF",
      homework: "#CCC014",
      exam: "#323739",
      media: "#00ad59",
      announcement: "#929799",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1240,
        xl: 1680,
      },
    },
  },
});

import log from 'loglevel';

/**
 * Filter and handle the error messages
 * @param {Object} error
 */
export function errorMessageHandler(error) {
  if (error.name !== 'AbortError') {
    log.error(error);
  }
}

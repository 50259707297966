import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../components/general/Layout";
import SubModulesOverview from "../components/subModules/SubModulesOverview";
import TrainingsDescription from "../components/trainings/TrainingsDescription";
import TrainingsInformation from "../components/trainings/TrainingsInformation";
import { getTrainingEnrichData } from "../dao/trainings";

export default function TrainingsDetailScreen() {
  const location = useLocation();
  const { training } = location.state;
  const [trainingEnrichedData, setTrainingEnrichedData] = useState();

  useEffect(() => {
    getTrainingEnrichData(training.enrichApiURL)
      .then((res) => {
        setTrainingEnrichedData(res);
      });
  }, [training]);

  return (
    <Layout title={training.title} showBackButton={true}>
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Paper
          sx={{
            padding: 2,
          }}
        >
          <TrainingsInformation training={training} enrichedData={trainingEnrichedData}/>
          {trainingEnrichedData?.subModules.length > 0 &&
            <SubModulesOverview training={training} enrichedData={trainingEnrichedData}/>
          }
          <TrainingsDescription enrichedData={trainingEnrichedData}/>
        </Paper>
      </Box>
    </Layout>
  );
}

import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorCard from "../components/general/ErrorCard";
import Layout from "../components/general/Layout";
import Loading from "../components/general/Loading";
import TrainingsItem from "../components/trainings/TrainingsItem";
import { filterTrainings } from "../helpers/trainings";
import useTrainings from "../hooks/useTrainings";
import { ReactComponent as Animation } from "../assets/loading-animation.svg";
import { Typography } from "@mui/material";

export default function TrainingsScreen() {
  const { t } = useTranslation();
  const [trainings, trainingsLoading, hasError, error] = useTrainings();
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [searchValue, setSearchValue] = useState(window?.sessionStorage.getItem('trainingSearch') || '');
  const [searchResultsLoading, setSearchResultsLoading] = useState(false);

  useEffect(() => {
    if (trainings) {
      setSearchResultsLoading(true);
      setFilteredTrainings(filterTrainings(trainings, searchValue));
      setSearchResultsLoading(false);
    }
  }, [trainings, searchValue]);

  useEffect(() => {
    window?.sessionStorage.setItem('trainingSearch', searchValue);
  }, [searchValue]);

  if (trainingsLoading) {
    return <Loading/>;
  }

  return (
    <Layout
      title={t("trainings")}
      search={searchValue}
      setSearch={setSearchValue}
      searchPlaceholder={t("search_trainings")}
    >
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 3,
        }}
      >
        {hasError &&
          <ErrorCard errorMessage={error}/>
        }

        {!(filteredTrainings.length) &&
          <Typography variant="p">
            {t("no_trainings_found")}
          </Typography>
        }

        {filteredTrainings &&
          filteredTrainings.map((training, index) => (
            <TrainingsItem training={training} key={`training_${index}`} />
          ))
        }
      </Box>

      {searchResultsLoading && searchValue && (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Animation />
        </Box>
      )}
    </Layout>
  );
}

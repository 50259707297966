import {
  TRAINING_TYPE_CLASSROOM,
  TRAINING_TYPE_EXAM,
  TRAINING_TYPE_LEARNING_PROGRAM,
  TRAINING_TYPE_ONLINE_MODULE,
  TRAINING_TYPE_TASK,
  TRAINING_TYPE_VIRTUAL_CLASSROOM,
} from "../Constants";
import i18next from "i18next";

export function getSubModuleTitle(subModule) {
  switch (subModule) {
    case TRAINING_TYPE_CLASSROOM:
      return i18next.t("trainingsDays");
    case TRAINING_TYPE_EXAM:
      return i18next.t("exams");
    case TRAINING_TYPE_TASK:
      return i18next.t("homework");
    case TRAINING_TYPE_ONLINE_MODULE:
      return i18next.t("onlineModule");
    case TRAINING_TYPE_LEARNING_PROGRAM:
      return i18next.t("learningProgram");
    case TRAINING_TYPE_VIRTUAL_CLASSROOM:
      return i18next.t("virtualClassroom");
    default:
      return '';
  }
}

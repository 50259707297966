import { useState, useEffect } from "react";
import { getAnnouncements } from "./../dao/announcements";

export default function useAnnouncements() {
  const [announcements, setAnnouncements] = useState([]);
  const [announcementsLoading, setAnnouncementsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  const fetchAnnouncementData = () => {
    setAnnouncementsLoading(true);
    getAnnouncements()
      .then((res) => {
        setAnnouncements(res.items);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.statusText);
      })
      .finally(() => setAnnouncementsLoading(false));
  };

  useEffect(() => {
    fetchAnnouncementData();
  }, []);

  return [announcements, announcementsLoading, hasError, error];
}

import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Loading from '../components/general/Loading';
import { decrypt } from './../helpers/decrypt';
import { convertParamsToObject } from './../helpers/urlParamHelpers';
import useUserContext from './../hooks/useUserContext';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [ content, setContent ] = useState(null);
  const { user, setUser } = useUserContext();

  useEffect(() => {
    if (content !== null) {
      setUser({
        email: content && content.email,
        name: content && content.name,
        refresh_token: content && content.refreshToken,
        id: content && content.id,
      });
    }
    // setUser is no dependancy
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
if (!authState || !authState?.isAuthenticated || !user.id) {
  const originalUri = toRelativeUrl(
    window.location.href,
    window.location.origin
  );
  if (window.location.search) {
    const encryptedParams = convertParamsToObject(
      window.location.href.split("?")[1]
    );
    if (!content && encryptedParams.content) {
      const decryptedContent = JSON.parse(decrypt(encryptedParams.content));
      setContent(decryptedContent);

      const tokenManagerOptions = {
        // Save token for half an hour just to be sure that it never exceeds the duration in Okta (1 hour)
        expiresAt: 1800 + Math.floor(Date.now() / 1000),
        scopes: "offline_access openid email profile",
      };

      oktaAuth.tokenManager.add("accessToken", {
        ...tokenManagerOptions,
        accessToken: decryptedContent.accessToken,
      });
      oktaAuth.tokenManager.add("idToken", {
        ...tokenManagerOptions,
        idToken: decryptedContent.idToken,
      });
      oktaAuth.tokenManager.add("refreshToken", {
        ...tokenManagerOptions,
        refreshToken: decryptedContent.refreshToken,
      });
    }
  } else {
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect(originalUri);
  }
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authState || !authState?.isAuthenticated) {
    return <Loading />;
  }

  return (<Outlet />);
}

import { useTranslation } from "react-i18next";
import Layout from "../components/general/Layout";
import { Typography, Box } from "@mui/material";
import { theme } from "./../setting";
import { Link } from "react-router-dom";

export default function DisclaimerScreen() {
  const { t } = useTranslation();

  return (
    <Layout title={t("disclaimer")} showBackButton={true} backToMenu={true}>
      <Box
        sx={{
          background: theme.palette.white,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 3,
          display: "flex",
          flexDirection: "column",
          marginTop: 3,
        }}
      >
        <Typography
          variant="p"
          sx={{
            marginTop: 3,
          }}
        >
          Voor de juistheid, volledigheid en actualiteit van deze website kan
          Pon's Automobielhandel B.V. niet instaan. De informatie op deze site
          kan op ieder moment en zonder aankondiging worden gewijzigd.
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 2,
          }}
        >
          Pon's Automobielhandel B.V. kan niet garanderen dat deze site foutloos
          en ononderbroken functioneert en aanvaardt hiervoor geen enkele
          aansprakelijkheid. Pon's Automobielhandel B.V. aanvaardt geen enkele
          aansprakelijkheid voor de inhoud van het Pon Academy leerportaal of
          van externe websites waarnaar wordt doorgelinkt.
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 2,
          }}
        >
          Pon's Automobielhandel B.V. is niet aansprakelijk voor enige schade
          ten gevolge van het gebruik (of het niet kunnen gebruiken) van het Pon
          Academy leerportaal en de gedownloade informatie en/of websites.
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginTop: 2,
          }}
        >
          Bij een bezoek aan de website worden persoonsgegevens verzameld. Graag
          verwijzen wij u naar het{" "}
          <Link to="/nl/privacy-statement">Privacy-</Link> en{" "}
          <Link to="/nl/cookie-statement">cookie</Link> statement.
        </Typography>
      </Box>
    </Layout>
  );
}

import { Box } from "@mui/system";
import { theme } from "../../setting";
import { useTranslation } from "react-i18next";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import CropFreeIcon from "@mui/icons-material/CropFree";
import CollectionsIcon from "@mui/icons-material/Collections";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";

import Navigation from "../navigation/Navigation";

/**
 * The footer
 * Last item toggles drawer
 *
 * @return {JSX.Element}
 */
export default function Footer({
  handleOpenDrawer,
  value,
  setValue,
  openDrawer,
  setOpenDrawer,
}) {
  const { t } = useTranslation();

  const BOTTOM_NAVIGATION_ITEMS = [
    {
      title: t("dashboard"),
      path: "/nl/",
      icon: <HomeIcon />,
    },
    {
      title: t("trainings"),
      path: "/nl/trainingen",
      icon: <SchoolIcon />,
    },
    {
      title: t("qr_scan"),
      path: "/nl/presence",
      icon: <CropFreeIcon />,
    },
    {
      title: t("media_library"),
      path: "/nl/mediatheek",
      icon: <CollectionsIcon />,
    },
  ];

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.white,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
          elevation={3}
        >
          <BottomNavigation showLabels value={value} onChange={handleChange}>
            {BOTTOM_NAVIGATION_ITEMS.map((item, i) => {
              return (
                <BottomNavigationAction
                  key={`${item.title}_${i}`}
                  label={item.title}
                  component={Link}
                  to={item.path}
                  value={item.path}
                  icon={item.icon}
                  onClick={() => setOpenDrawer(false)}
                  sx={{
                    minWidth: "70px",
                    "& .MuiBottomNavigationAction-label": {
                      marginTop: "2px",
                      fontSize: "11px",
                    },
                    "& .MuiBottomNavigationAction-label.Mui-selected": {
                      fontSize: "11px",
                    },
                  }}
                />
              );
            })}
            <BottomNavigationAction
              icon={<MenuIcon />}
              component="a"
              label={t("other_menu_item")}
              onClick={() => handleOpenDrawer(!openDrawer)}
              sx={{
                minWidth: "70px",
                "& .MuiBottomNavigationAction-label": {
                  marginTop: "2px",
                  fontSize: "11px",
                },
                "& .MuiBottomNavigationAction-label.Mui-selected": {
                  fontSize: "11px",
                },
              }}
            />
          </BottomNavigation>
          <Navigation {...{ openDrawer, handleOpenDrawer }} />
        </Paper>
      </Box>
    </>
  );
}

import moment from "moment";
import i18next from "i18next";
import {
  TRAINING_TYPE_CLASSROOM,
  TRAINING_TYPE_EXAM,
  TRAINING_TYPE_LEARNING_PROGRAM,
  TRAINING_TYPE_ONLINE_MODULE,
  TRAINING_TYPE_TASK,
  TRAINING_TYPE_VIRTUAL_CLASSROOM,
} from "../Constants";

export function getStatusTrainingOverview(type, status, startDate) {
  switch (type) {
    case TRAINING_TYPE_CLASSROOM:
    case TRAINING_TYPE_VIRTUAL_CLASSROOM: {
      const daysUntilStart = moment(startDate).diff(moment(), "days") + 1;

      if (daysUntilStart === 0) {
        return i18next.t("trainings_today");
      } else if (daysUntilStart < 0) {
        return i18next.t("traings_finished", {
          date: moment(startDate).format("D MMMM YYYY"),
        });
      }

      if (type === TRAINING_TYPE_VIRTUAL_CLASSROOM) {
        return i18next.t("virtual_classroom_days_until_start", {
          date: moment(startDate).format("D MMMM YYYY"),
        });
      } else {
        return i18next.t("trainings_days_until_start", {
          date: moment(startDate).format("D MMMM YYYY"),
        });
      }
    }
    case TRAINING_TYPE_EXAM:
      return i18next.t("trainings_exam", { status: status });
    case TRAINING_TYPE_TASK:
      return i18next.t("trainings_homework", { status: status });
    case TRAINING_TYPE_ONLINE_MODULE:
      return i18next.t("trainings_online", { status: status });
    case TRAINING_TYPE_LEARNING_PROGRAM:
      return i18next.t("trainings_learning_program", {
        date: moment(startDate).format("D MMMM YYYY"),
      });
    default:
      return '';
  }
}

export function getStatusTrainingDetail(module) {
  switch (module.type) {
    case TRAINING_TYPE_CLASSROOM:
      return `${moment(module.startDate).format("D MMMM YYYY H:mm")} - ${moment(module.endDate).format("H:mm")}`
    case TRAINING_TYPE_EXAM:
      return module.status;
    case TRAINING_TYPE_TASK: {
      const todayTime = new Date().getTime();
      if (new Date(module.endDate).getTime() > todayTime) {
        const date = moment(module.endDate).format("D MMMM YYYY");
        return i18next.t("finish_before", { date: date });
      } else {
        return module.status;
      }
    }
    case TRAINING_TYPE_ONLINE_MODULE:
    case TRAINING_TYPE_VIRTUAL_CLASSROOM:
      return `${module.id} - ${module.status}`;
    case TRAINING_TYPE_LEARNING_PROGRAM:
      return i18next.t("trainings_learning_program", {
        date: moment(module.startDate).format("D MMMM YYYY"),
      });
    default:
      return '';
  }
}

import { useCallback, useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import moment from "moment";
import 'moment/locale/nl';
import { oktaAuth } from './auth/oktaAuth';
import { RequiredAuth } from './auth/RequiredAuth';
import DashboardScreen from './pages/DashboardScreen';
import DisclaimerScreen from './pages/DisclaimerScreen';
import PrivacyStatementScreen from './pages/PrivacyStatementScreen';
import CookieStatementScreen from './pages/CookieStatementScreen';
import TermsOfUseScreen from "./pages/TermsOfUseScreen";
import AnnouncementsScreen from "./pages/AnnouncementsScreen";
import QRcodeScreen from "./pages/QRcodeScreen";
import { theme } from "./setting";
import { ThemeProvider } from "@mui/material/styles";
import Loading from "./components/general/Loading";
import i18n from "./i18n";
import LanguageDetector from "./i18n/LanguageDetector";
import { UserProvider } from "./userContext";
import { setCookies } from "./helpers/cookies";
import CookieBanner from "./components/general/CookieBanner";
import ScrollToTop from "./components/general/ScrollToTop";
import { Cookies } from "react-cookie";
import Installbanner from "./components/pwa/Installbanner";
import TrainingsScreen from "./pages/TrainingsScreen";
import TrainingsDetailScreen from "./pages/TrainingsDetailScreen";
import SubModuleDetailScreen from "./pages/SubModuleDetailsScreen";
import MediaLibraryScreen from "./pages/MediaLibraryScreen";
import MediaFileScreen from "./pages/MediaFilesScreen";

const lang = i18n.language;
const base = `/${lang}`;

moment.locale(lang);

export default function App() {
  const navigate = useNavigate();

  // BEGIN: Temporary fix for duplicate call
  // https://github.com/okta/okta-react/issues/227
  const navigateRef = useRef(navigate);
  const restoreOriginalUri = useCallback((_, originalUri = "/") => {
    const url = toRelativeUrl(originalUri, globalThis.location.origin);
    navigateRef.current(url);
  }, []);

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);
  // END: Temporary fix for duplicate call

  const cookies = new Cookies();
  const hasSetCookiePreferences = cookies.get("academyHasPreferences");
  const hasCookiesAccepted = cookies.get("academyAcceptCookies");
  const [cookiesVisible, setCookiesVisible] = useState(false);

  useEffect(() => {
    checkCookies();
  });

  /**
   * Check if cookies are already set
   */
  const checkCookies = () => {
    if (typeof hasSetCookiePreferences === "undefined") {
      setCookiesVisible(true);
    }
    if (hasCookiesAccepted === true) {
      // Do stuff here
    }
  };

  const acceptCookies = () => {
    setCookiesVisible(false);
    setCookies(true);
  };

  /**
   * Decline cookies
   */
  const declineCookies = () => {
    setCookiesVisible(false);
    setCookies(false);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <ScrollToTop />
          <Routes>
            <Route path={base} />
            <Route
              path="implicit/callback"
              element={<LoginCallback loadingElement={<Loading />} />}
            />
            <Route path="/" element={<LanguageDetector />} />
            <Route path="/nl" element={<RequiredAuth />}>
              <Route index element={<DashboardScreen />} />
            </Route>
            <Route path="/nl/presence" element={<RequiredAuth />}>
              <Route index element={<QRcodeScreen />} />
            </Route>
            <Route path="/nl/disclaimer" element={<RequiredAuth />}>
              <Route index element={<DisclaimerScreen />} />
            </Route>
            <Route path="/nl/privacy-statement" element={<RequiredAuth />}>
              <Route index element={<PrivacyStatementScreen />} />
            </Route>
            <Route path="/nl/cookie-statement" element={<RequiredAuth />}>
              <Route index element={<CookieStatementScreen />} />
            </Route>
            <Route path="/nl/gebruiksvoorwaarden" element={<RequiredAuth />}>
              <Route index element={<TermsOfUseScreen />} />
            </Route>
            <Route path="/nl/meldingen" element={<RequiredAuth />}>
              <Route index element={<AnnouncementsScreen />} />
            </Route>
            <Route path="/nl/trainingen" element={<RequiredAuth />}>
              <Route index element={<TrainingsScreen/>} />
            </Route>
            <Route path="/nl/trainingen/:trainingRecordId" element={<RequiredAuth />}>
              <Route index element={<TrainingsDetailScreen/>} />
            </Route>
            <Route path="/nl/trainingen/:trainingRecordId/:moduleId" element={<RequiredAuth />}>
              <Route index element={<SubModuleDetailScreen/>} />
            </Route>
            <Route path="/nl/mediatheek" element={<RequiredAuth />}>
              <Route index element={<MediaLibraryScreen/>} />
            </Route>
            <Route path="/nl/mediatheek/:folderId" element={<RequiredAuth />}>
              <Route index element={<MediaFileScreen/>} />
            </Route>
          </Routes>
          <CookieBanner
            visible={cookiesVisible}
            acceptCookies={acceptCookies}
            declineCookies={declineCookies}
          />
        </UserProvider>
      </ThemeProvider>
      <Installbanner />
    </Security>
  );
}

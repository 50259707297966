import { oktaAuth } from "../auth/oktaAuth";

const apiRoot = process.env.REACT_APP_API_URL;

/**
 * @param {Object<string, string>} [options] `Fetch` options Object
 *
 * @returns {Promise<Object<string, string>>} Resolves to options
 */
async function addOktaBearerToFetchOptions(options) {
  const allOptions = { ...options };
  const accessToken = await oktaAuth.getAccessToken();
  const headerValue = `Bearer ${accessToken}`;
  const headerName = "Authorization";

  // Other headers might already exist
  if (allOptions.headers) {
    // headers can be `Headers`
    if (allOptions.headers.set) {
      allOptions.headers.set(headerName, headerValue);
    } else {
      // or simple JSON
      allOptions.headers[headerName] = headerValue;
    }
  } else {
    // Just add simple JSON instead
    allOptions.headers = {
      [headerName]: headerValue,
    };
  }

  // Original object is modified, but returning to be polite
  return allOptions;
}

/**
 * GET data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {Object<string, string>} [options] `Fetch` options Object
 */
export async function getDataFromApi(endpoint, options = {}) {
  const authOptions = await addOktaBearerToFetchOptions(options);

  return fetch(`${apiRoot}${endpoint}`, {
    method: "GET",
    ...authOptions,
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(res.statusText);
      }
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * POST data to the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 */
export async function postDataToApi(endpoint, options = {}) {
  const apiRoot = process.env.REACT_APP_API_URL;
  const authOptions = await addOktaBearerToFetchOptions(options);

  return fetch(`${apiRoot}${endpoint}`, {
    method: "POST",
    ...authOptions,
  }).then((res) => {
    if(!res.ok) {
      throw Error(res.statusText);
    }
    return res;
  }).catch((err) => {
    return Promise.reject(err);
  });
}

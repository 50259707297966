import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import { locales } from '../i18n';

/**
 * @param {string} locale Locale as a string (ll-RR)
 * @param {string} url A path to another page
 * @returns {string} Path with locale prepended (or replaced)
 */
export function localizeURL(locale, url) {
  const languageString = locales.join('|');
  const urlRE = new RegExp(`(?:/(?:${languageString}))?(/.*)`);
  return url.replace(urlRE, `/${locale}$1`);
}

/**
 * @param {object} props React props
 * @param {locale} [props.locale] Optional overwrite for the locale
 * @param {string} props.to The path to link to (existing locale optional)
 * @param {JSX.ElementClass} props.component The Link-like component to wrap
 * @returns {JSX.Element} A `react-router-dom/Link`, with correct locale
 */
function LinkI18nWrapper({
  locale: requestedLocale,
  to,
  component: WrappedComponent,
  ...props
}) {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const fullURL = localizeURL(requestedLocale || currentLocale, to);
  return <WrappedComponent { ...{ to: fullURL, ...props } } />;
}

/**
 * @param {object} props React props
 * @param {locale} [props.locale] Optional overwrite for the locale
 * @param {string} props.to The path to link to (existing locale optional)
 * @returns {JSX.Element} A `react-router-dom/Link`, with correct locale
 */
export default function Link({ ...props }) {
  return <LinkI18nWrapper { ...{ component: RouterLink, ...props } } />;
}

/**
 * @param {object} props React props
 * @param {locale} [props.locale] Optional overwrite for the locale
 * @param {string} props.to The path to link to (existing locale optional)
 * @returns {JSX.Element} A `react-router-dom/NavLink`, with correct locale
 */
export function NavLink({ ...props }) {
  return <LinkI18nWrapper { ...{ component: RouterNavLink, ...props } } />;
}

import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getSubModuleTitle } from "../../utils/subModuleTitle";
import { theme } from "../../setting";
import TrainingsContent from "../trainings/TrainingsContent";
import SubModuleLink from "./SubModuleLink";
import { getStatusTrainingDetail } from "../../utils/status";

export default function SubModulesOverview({ training, enrichedData }) {
  return (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      {Object.keys(training.subModules).map((subModule) => (
        <Box
          key={subModule}
          sx={{
            marginBottom: 2,
          }}
        >
          {training.subModules[subModule].length > 0 &&
            <Typography
              variant='h6'
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: 1,
              }}
            >
              {getSubModuleTitle(subModule)}
            </Typography>
          }
          {training.subModules[subModule].map((module, i) => (
            <Box key={`${module}_${i}`}>
              <SubModuleLink
                url={`/nl/trainingen/${training.trainingRecordId}/${module.id}`}
                state={{ module: module, enrichedData: enrichedData }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderTop: `1px solid ${theme.palette.grey}`,
                    borderBottom: `1px solid ${theme.palette.grey}`,
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: 2,
                    }}
                  >
                    <TrainingsContent content={module} status={getStatusTrainingDetail(module)} />
                  </Box>
                  <ArrowForwardIosIcon />
                </Box>
              </SubModuleLink>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

import { Link } from "react-router-dom";
import { theme } from "../../setting";

/**
 * Link component
 *
 * @param {string} url // Link to redirect to
 * @param {object} state // State to send to the redirect page
 *
 * @return {JSX.Element}
 */
export default function SubModuleLink({ url, state = {}, children }) {
  const linkStyle = {
    textDecoration: 'none',
    color: theme.palette.font.main,
  };

  return (
    <Link
      to={url}
      state={state}
      style={linkStyle}
    >
      {children}
    </Link>
  );
}

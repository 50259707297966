import { Box, Typography } from "@mui/material";
import { theme } from "../../setting";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment";
import { getMapsLink } from "../../helpers/maps";
import { useEffect, useState } from "react";

export default function TrainingInformation({ module, sessionData }) {
  const [mapsLink, setMapsLink] = useState('');

  const linkStyle = {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  };

  useEffect(() => {
    if (sessionData?.facility?.contactInfo) {
      setMapsLink(getMapsLink(sessionData?.facility?.contactInfo));
    }
  }, [sessionData]);

  return (
    <>
      {module.startDate && module.endDate &&
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <AccessTimeIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant='p'>
            {moment(module.startDate).format("D MMMM YYYY HH:mm")} - {moment(module.endDate).format("HH:mm")}
          </Typography>
        </Box>
      }

      {sessionData?.facility &&
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <LocationOnIcon
            sx={{
              marginRight: 2,
            }}
          />
          <a style={linkStyle} href={mapsLink}>
            {sessionData?.facility?.name &&
              <Typography variant='p'>
                {sessionData.facility.name}
              </Typography>
            }
            {sessionData?.facility?.contactInfo &&
              <Typography variant='p'>
                {sessionData.facility.contactInfo}
              </Typography>
            }
          </a>
        </Box>
      }

      {sessionData?.instructor &&
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <PersonIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant='p'>
            {sessionData?.instructor}
          </Typography>
        </Box>
      }
    </>
  );
}

const nl = {
  translation: {
    dashboard: "Dashboard",
    disclaimer: "Disclaimer",
    logged_in_as: "Ingelogd als:",
    announcements: "Meldingen",
    terms_of_use: "Gebruiksvoorwaarden",
    privacy_statement: "Privacy statement",
    cookie_statement: "Cookie statement",
    trainings: "Actieve trainingen",
    qr_scan: "QR Scan",
    media_library: "Mediatheek",
    cookie_button_accept: "Accepteren",
    cookie_button_decline: "Afwijzen",
    other_menu_item: "Overig",
    other_page_title: "Overig",
    trainings_today: "Trainingsdag - vandaag",
    days: "dagen",
    day: "dag",
    traings_finished: "Trainingsdag - {{date}}",
    trainings_days_until_start: "Trainingsdag - {{date}}",
    virtual_classroom_days_until_start: "{{date}} - Virtueel klaslokaal",
    trainings_exam: "Examen - {{status}}",
    trainings_homework: "Huiswerkopdracht - {{status}}",
    trainings_online: "Online module - {{status}}",
    trainings_learning_program: "{{date}} - Online",
    most_recent_announcement: "Meest recente mededeling",
    retry_button: "Herlaad applicatie",
    presence_page_title: "Aanwezigheid",
    presence_description: "Scan de QR Code om je aanwezigheid te melden.",
    presence_retry_button: "Opnieuw scannen",
    presence_reported_success: "Je bent succesvol aanwezig gemeld!",
    presence_reported_failure: "Aanwezigheid melden niet gelukt.",
    presence_something_went_wrong: "Er ging iets mis",
    trainingDurationMultipleDays: "Duurt {{trainingDuration}} dagen",
    trainingDurationSingleDay: "Duurt {{trainingDuration}} dag",
    trainingDuration: "Duurt {{duration}}",
    duration: "(duur: {{duration}})",
    duration_from: "Vanaf {{startTime}} {{extraTimeInfo}}",
    trainingsDays: "Trainingsdagen",
    homework: "Huiswerk",
    onlineModule: "Online onderdelen",
    exams: "Examens",
    learningProgram: "Leer programma",
    virtualClassroom: "Virtueel klaslokaal",
    description: "Omschrijving",
    goals: "Leerdoelen",
    next_training: "Eerstvolgende training",
    no_files_found: "Geen bestanden gevonden",
    no_folders_found: "Geen mappen gevonden",
    search_media_library: "Doorzoek mediatheek",
    back_to_begin: "Terug naar begin",
    finish_before: "Afronden voor {{date}}",
    no_trainings_found: "Geen trainingen gevonden.",
    search_trainings: "Doorzoek trainingen",
    search_in_drive: "Zoek in Drive op: {{id}}",
    homework_notice: "Dit is een huiswerk opdracht, log in op Drive op een laptop of desktop om deze te bekijken.",
    pwa_notice_title: "Sneller toegang to de Academy app?",
    pwa_notice_body: "Voeg de Academy app toe aan je startscherm om deze op volledig scherm te gebruiken.",
    pwa_notice_step_1: "1. Klik op het deelicoon in de menubalk onderaan",
    pwa_notice_step_2: "2. Klik op 'Zet op beginscherm'",
    cancel: "Annuleer",
    back: "Terug",
    log_out: "Uitloggen",
    medialibrary_notice: "Om media bestanden te openen en te bekijken, zorg ervoor dat je browser pop-ups toestaat.",
  },
};

export default nl;

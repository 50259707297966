import { Box, Typography } from "@mui/material";
import moment from "moment";
import { theme } from "../../setting";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CodeIcon from '@mui/icons-material/Code';
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getMapsLink } from "../../helpers/maps";
import PersonIcon from "@mui/icons-material/Person";

export default function TrainingsInformation({ training, enrichedData }) {
  const { t } = useTranslation();

  const startDate = moment(training.startDate).format("D MMMM YYYY");
  const startTime = moment(training.startDate).format("HH:mm");

  const linkStyle = {
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
    color: theme.palette.primary.main,
  };

  let trainingDuration;

  const sessionData = enrichedData?.sessions[0];

  if ((enrichedData?.enrichment.typeLabel || '').toLowerCase() !== 'online') {
    if (training.subModules) {
      if (enrichedData?.enrichment.duration) {
        trainingDuration = t('trainingDuration', {duration: enrichedData?.enrichment.duration});
      }
    } else {
      const extraTimeInfo = enrichedData?.enrichment.duration
        ? t('duration', {duration: enrichedData?.enrichment.duration})
        : '';

      trainingDuration = t('duration_from', {startTime, extraTimeInfo});
    }
  }

  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontWeight: "bold",
        }}
      >
        {training.title}
      </Typography>
      <Typography
        variant='p'
        sx={{
          color: theme.palette.font.lighter,
        }}
      >
        {training.id} - {enrichedData?.enrichment.typeLabel}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <AccessTimeIcon
          sx={{
            marginRight: 2,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant='p'>
            {startDate}
          </Typography>
          <Typography variant='p'>
            {trainingDuration}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <CodeIcon
          sx={{
            marginRight: 2,
          }}
        />
        <Typography variant='p'>{training.status}</Typography>
      </Box>
      {module.startDate && module.endDate && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <AccessTimeIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant="p">
            {moment(module.startDate).format("D MMMM YYYY HH:mm")} -{" "}
            {moment(module.endDate).format("HH:mm")}
          </Typography>
        </Box>
      )}

      {sessionData?.facility && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <LocationOnIcon
            sx={{
              marginRight: 2,
            }}
          />
          <a
            style={linkStyle}
            href={getMapsLink(sessionData?.facility?.contactInfo)}
          >
            {sessionData?.facility?.name && (
              <Typography variant="p">{sessionData.facility.name}</Typography>
            )}
            {sessionData?.facility?.contactInfo && (
              <Typography variant="p">
                {sessionData.facility.contactInfo}
              </Typography>
            )}
          </a>
        </Box>
      )}

      {sessionData?.instructor && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <PersonIcon
            sx={{
              marginRight: 2,
            }}
          />
          <Typography variant="p">{sessionData?.instructor}</Typography>
        </Box>
      )}
    </>
  );
}

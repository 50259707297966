/**
 * convert URL params to object
 *
 * @param {boolean} paramsString - string with URL params
 * @return {object} params
 */
export function convertParamsToObject(paramsString) {
  const params = {};

  // Split the param string in individual elements
  paramsString.split("&").forEach((unsplittedParam) => {
    const splitted = unsplittedParam.split("=");
    // Create key value pair from param
    params[splitted[0]] = unsplittedParam.slice(
      unsplittedParam.indexOf("=") + 1
    );
  });

  return params;
}

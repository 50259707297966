import TvIcon from "@mui/icons-material/Tv";
import CreateIcon from "@mui/icons-material/Create";
import PublicIcon from "@mui/icons-material/Public";
import SchoolIcon from "@mui/icons-material/School";
import { theme } from "../../setting";
import {
  TRAINING_TYPE_EXAM,
  TRAINING_TYPE_LEARNING_PROGRAM,
  TRAINING_TYPE_ONLINE_MODULE,
  TRAINING_TYPE_TASK,
  TRAINING_TYPE_VIRTUAL_CLASSROOM,
} from "../../Constants";

export default function TrainingTypeIcon({ type, sx }) {
  switch (type) {
    case TRAINING_TYPE_EXAM:
      return (
        <CreateIcon
          sx={{ ...sx, backgroundColor: theme.palette.subjectColors.exam }}
        />
      );
    case TRAINING_TYPE_TASK:
      return (
        <CreateIcon
          sx={{ ...sx, backgroundColor: theme.palette.subjectColors.homework }}
        />
      );
    case TRAINING_TYPE_ONLINE_MODULE:
    case TRAINING_TYPE_VIRTUAL_CLASSROOM:
      return (
        <PublicIcon
          sx={{
            ...sx,
            backgroundColor: theme.palette.subjectColors.onlineModule,
          }}
        />
      );
    case TRAINING_TYPE_LEARNING_PROGRAM:
      return (
        <SchoolIcon
          sx={{
            ...sx,
            backgroundColor: theme.palette.subjectColors.learningProgram,
          }}
        />
      );
    default:
      return (
        <TvIcon
          sx={{ ...sx, backgroundColor: theme.palette.subjectColors.classroom }}
        />
      );
  }
}

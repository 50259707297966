import { useEffect, useState } from "react";

import { getMediaFiles, getMediaFolders } from "../dao/media";

export default function useMediaFolders() {
  const [mediaFolders, setMediaFolders] = useState([]);
  const [mediaFoldersLoading, setMediaFoldersLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  const fetchMediaFolders = () => {
    setMediaFoldersLoading(true);
    getMediaFolders()
      .then((res) => {
        setMediaFolders(res.files);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.message);
      })
      .finally(() => {
        setMediaFoldersLoading(false);
      });
  };

  useEffect(() => {
    fetchMediaFolders();
  }, []);

  return [mediaFolders, mediaFoldersLoading, hasError, error];
}

export function useMediaFiles(folderId, pageToken) {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [mediaFilesLoading, setMediaFilesLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  const fetchMediaFiles = () => {
    setMediaFilesLoading(true);
    getMediaFiles(folderId, pageToken)
      .then((res) => {
        setMediaFiles(res);
      })
      .catch((err) => {
        setHasError(true);
        setError(err.message);
      })
      .finally(() => {
        setMediaFilesLoading(false);
      });
  };

  useEffect(() => {
    if (folderId) {
      fetchMediaFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, pageToken]);

  return [mediaFiles, mediaFilesLoading, hasError, error];
}

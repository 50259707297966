import { getFile } from "../dao/media";

/**
 * Download the file.
 *
 * @param {object} file // File data
 */
export async function downloadFile(file) {
  await getFile(file.id, file.mimeType).then((res) => {
    const fileURL = URL.createObjectURL(res);
    window.open(fileURL);
  });
}

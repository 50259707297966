import { Paper, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { theme } from "./../../setting";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import Button from "./Button";

/**
 * Error message card
 *
 * @param {string} errorMessage // The error message to display
 *
 * @return {JSX.Element}
 */
export default function ErrorCard({ errorMessage }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          background: theme.palette.white,
          marginTop: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 2,
          }}
        >
          <ErrorIcon
            sx={{
              marginRight: 2,
              color: theme.palette.status.attentionColor,
            }}
          />
          <Typography
            variant='p'
            sx={{
              marginRight: 2,
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
        <Button
          onClickHandler={() => {
            navigate(0);
          }}
        >
          {t("retry_button")}
        </Button>
      </Paper>
    </>
  );
}

import { Cookies } from "react-cookie";

/**
 * Set Cookies
 *
 * @param {boolean} value - Set statstics cookie to true or false
 * @return {void}
 */
export function setCookies(value) {
  const cookies = new Cookies();
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 30);

  cookies.set("academyAcceptCookies", value, {
    path: "/",
    domain: process.env.COOKIE_DOMAIN,
    expires: expireDate,
  });

  cookies.set("academyHasPreferences", true, {
    path: "/",
    domain: process.env.COOKIE_DOMAIN,
    expires: expireDate,
  });
}

/**
 * Remove google analytics cookies
 *
 * @return {void}
 */
export function removeGaCookies() {
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
}

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TrainingsDescription({ enrichedData }) {
  const { t } = useTranslation();

  return (
    <>
      {enrichedData?.enrichment?.description && (
        <>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: 1,
              marginTop: 4,
            }}
          >
            {t("description")}
          </Typography>
          <Typography
            variant='p'
            sx={{
              whiteSpace: "pre-line",
              display: 'block',
              marginBottom: 4,
            }}
            dangerouslySetInnerHTML={{
              __html: enrichedData?.enrichment?.description,
            }}
          />
        </>
      )}
      {enrichedData?.enrichment?.objectives.length > 0 && (
        <>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: 1,
            }}
          >
            {t("goals")}
          </Typography>
          <ul>
            {enrichedData?.enrichment?.objectives.map((objective) => (
              <li
                key={objective}
                style={{
                  marginBottom: '8px',
                }}
              >
                {objective}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

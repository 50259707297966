import React, { useState } from 'react';

// Create the context
const UserContext = React.createContext();

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    email: "",
    name: "",
    refresh_token: "",
    id: "",
  });

  // This component will render its children
  // wrapped around a UserContext's provider whose
  // value is set to the method defined above
  return (
    <UserContext.Provider value={{ user: user, setUser: setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

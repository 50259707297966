import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { downloadFile } from "../../helpers/downloadFile";

export default function MediaItem({ folder }) {
  const [activeDownload, setActiveDownload] = useState(false);
  const fileToDownload = (file) => {
    setActiveDownload(true);
    downloadFile(file).then(() => setActiveDownload(false));
  };

  return (
    <>
      <ListItemButton
        onClick={() => {
          fileToDownload(folder);
        }}
      >
        <ListItemIcon>
          <img
            src={folder.iconLink}
            style={{
              width: "20px",
            }}
            alt={folder.name}
          />
        </ListItemIcon>
        <ListItemText primary={folder.name} />
        <ListItemIcon>
          {activeDownload && <CircularProgress size={32} color="inherit" />}
        </ListItemIcon>
      </ListItemButton>
      <Divider
        sx={{
          width: "100%",
        }}
      />
    </>
  );
}

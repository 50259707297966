import { Box, Paper, Typography } from "@mui/material";
import TrainingsContent from "./TrainingsContent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SubModuleLink from "../subModules/SubModuleLink";
import { getStatusTrainingOverview } from "../../utils/status";

export default function TrainingsItem({ training }) {
  return (
    <SubModuleLink
      url={ `/nl/trainingen/${training.trainingRecordId}` }
      state={{ training: training }}
    >
      <Paper
        sx={{
          marginTop: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
          <Box
            sx={{
              "& .MuiBox-root:last-of-type": {
                marginBottom: 0,
              },
            }}
          >
            {training.subModules && Object.keys(training.subModules).length ? (
              <>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "bold",
                    display: "block",
                    marginBottom: 2,
                  }}
                >
                  {training.title}
                </Typography>
                {Object.keys(training.subModules).map((subModule) =>
                  training.subModules[subModule].map((module, index) => (
                    <TrainingsContent key={index} content={module} status={getStatusTrainingOverview(module.type, module.status, module.startDate)} />
                  ))
                )}
              </>
            ) : (
              <TrainingsContent content={training} status={getStatusTrainingOverview(training.type, training.status, training.startDate)} />
            )}
          </Box>
          <ArrowForwardIosIcon />
      </Paper>
    </SubModuleLink>
  );
}

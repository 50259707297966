import { Box } from "@mui/system";
import { theme } from "../../setting";
import { Paper } from "@mui/material";
import SearchBar from "./SearchBar";

/**
 * The header
 *
 * @param {string} title // Header title
 *
 * @return {JSX.Element}
 */
export default function Header({
  title,
  search = "",
  setSearch = false,
  searchPlaceholder = "",
}) {
  return (
    <>
      <Paper
        sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: "10" }}
        elevation={3}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <h3>
            {title.length > 30 ? `${title.substring(0, 30)}...` : title}
          </h3>
          {setSearch && (
            <SearchBar
              search={search}
              setSearch={setSearch}
              searchPlaceholder={searchPlaceholder}
            />
          )}
        </Box>
      </Paper>
    </>
  );
}

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { theme } from "./../setting";
import { Box } from "@mui/system";
import { Typography, Paper } from "@mui/material";
import { reportPresence } from "../dao/presence";
import Layout from "../components/general/Layout";
import useUserContext from "../hooks/useUserContext";
import Button from "../components/general/Button";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ReportPresenceAlert = ({ successState, reset }) => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ marginTop: 3 }} elevation={1}>
      <Box
        sx={{
          padding: 2,
        }}
      >
        {successState ? (
          <>
            <CheckCircleIcon
              sx={{
                margin: "0 auto",
                display: "flex",
                height: "90px",
                width: "90px",
                fill: theme.palette.status.positiveColor,
              }}
            />
            <Typography
              variant="p"
              sx={{
                display: "block",
                fontWeight: "bold",
                marginTop: 1,
                marginBottom: 2,
                textAlign: "center",
                color: theme.palette.status.positiveColor,
              }}
            >
              {t("presence_reported_success")}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="p"
              sx={{
                display: "block",
                fontWeight: "bold",
              }}
            >
              {t("presence_something_went_wrong")}
            </Typography>
            <Typography
              variant="p"
              sx={{
                marginTop: 1,
                marginBottom: 2,
                display: "block",
              }}
            >
              {t("presence_reported_failure")}
            </Typography>
          </>
        )}
        <Button onClickHandler={() => reset()}>
          {t("presence_retry_button")}
        </Button>
      </Box>
    </Paper>
  );
};

export default function QRcodeScreen() {
  const { t } = useTranslation();
  const lastResult = useRef();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [QRCodeScanned, setQRCodeScanned] = useState(false);
  const [reportPresencePending, setReportPresencePending] = useState(false);
  const [reportPresenceSuccess, setReportPresenceSuccess] = useState(null);

  const handleQRCodeScan = (result) => {
    // Guard to prevent hammering the API
    if (lastResult.current === result) {
      return;
    }
    lastResult.current = result;
    setReportPresencePending(true);
    if (result) {
      reportPresence(user.id, result).then((res) => {
        // Example QRcodeResult: http://www.drive.pon.com/QR/Attendance?https%3a%2f%2fwww.drive.pon.com%2fpon%2fxapi%2fTEC-MOHVTT-F1%23MO-2208-19&status=completed
        // True on success, false on error
        setReportPresenceSuccess(res);
        setQRCodeScanned(true);
      });
    } else {
      setReportPresenceSuccess(false);
    }
    setReportPresencePending(false);
  };

  const handleReset = () => {
    setQRCodeScanned(false);
    setReportPresencePending(false);
    setReportPresenceSuccess(null);
    lastResult.current = "";
    navigate("/nl/presence");
  };

  return (
    <Layout title={t("presence_page_title")}>
      {reportPresencePending === false && reportPresenceSuccess === null && (
        <>
          <Typography
            variant="p"
            sx={{
              marginTop: 3,
              display: "block",
              textAlign: "center",
            }}
          >
            {t("presence_description")}
          </Typography>
          <Box
            sx={{
              "& > section > section": {
                paddingTop: "75% !important",
                marginTop: "10px",
              },
            }}
          >
            <QrReader
              constraints={{ facingMode: "environment" }}
              onScan={(result) => {
                if (result !== null) {
                  handleQRCodeScan(result);
                }
              }}
              onError={() => {
                setQRCodeScanned(true);
                setReportPresencePending(false);
                setReportPresenceSuccess(false);
              }}
            />
          </Box>
        </>
      )}
      {/* If we have a result, the API call is finished and we have a succes/failure state */}
      {QRCodeScanned &&
        reportPresencePending === false &&
        reportPresenceSuccess !== null && (
          <ReportPresenceAlert
            successState={reportPresenceSuccess}
            reset={handleReset}
          />
        )}
    </Layout>
  );
}

/**
 * Detector for i18next-browser-languageDetector which only accepts a known
 * locale at the start of the URL. If anything else is found, it is ignored
 * and up to the other detectors to determine the best language.
 *
 * Usage:
 * ```
   import BrowserLanguageDetector from 'i18next-browser-languagedetector';
   const browserLanguageDetector = new BrowserLanguageDetector();
   browserLanguageDetector.addDetector(DetectKnownLocaleFromURL);
   i18n
    .use(browserLanguageDetector)
    .init({
      detection: {
        order: [
          DetectKnownLocaleFromURL.name,
          'localStorage',
          'sessionStorage',
          'navigator',
        ],
        knownLocales: ['en','nl'],
      },
    })
   ;
   ```
 */
const DetectKnownLocaleFromURL = {
  name: 'path-known-lang',
  lookup(options) {
    if (typeof window !== 'undefined') {
      // Split by forward slash and question mark, should result in a clean
      // locale after filtering out empty results
      const [
        language,
      ] = window.location.pathname.split(/[/?]/).filter((n) => n);
      return options.knownLocales?.includes(language) ? language : undefined;
    }
  },
};

export default DetectKnownLocaleFromURL;
